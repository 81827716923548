import { Row } from "react-bootstrap"

function Warn() {
    return(
        <div>
            In Kürze gibt es hier auch etwas zu sehen.
        </div>
    )
}

function Saison_17_18() {
    return(
        <div>
            <h1>Saison 2017/18</h1>
            <hr />
            <Row xs={1} md={1} lg={2} className='g-4'>
            <img src='img/galerie/mkxi01.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxi02.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxi03.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxi04.jpeg' className="img-fluid" ></img>
            <img src='img/galerie/mkxi05.jpeg' className="img-fluid" ></img>

            </Row>
        </div>
    )
} 
function Saison_18_19() {
    return(
        <div>
            <h1>Saison 2018/19</h1>
            <hr />
            <Row xs={1} md={1} lg={2} className='g-4'>
            <img src='img/galerie/mkxii01.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxii02.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxii03.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxii04.jpg' className="img-fluid" ></img>

            </Row>
        </div>
    )
} 
function Saison_20_21() {
    return(
        <div>
            <h1>Saison 2020/21 und 2019/20</h1>
            <hr />
            <Row xs={1} md={1} lg={2} className='g-4'>
            <img src='img/galerie/mkxiii01.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiii02.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiii03.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiii04.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiii05.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiii06.jpg' className="img-fluid" ></img>

            </Row>
        </div>
    )
} 
function Saison_21_22() {
    return(
        <div>
            <h1>Saison 2021/22</h1>
            <hr />
            <Row xs={1} md={1} lg={2} className='g-4'>
            <img src='img/galerie/mkxiv01.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiv02.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiv03.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiv04.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxiv05.jpg' className="img-fluid" ></img>

            </Row>
        </div>
    )
} 
function Saison_22_23() {
    return(
        <div>
            <h1>Saison 2022/23</h1>
            <hr />
            <Row xs={1} md={1} lg={2} className='g-4'>
            <img src='img/galerie/mkxdv01.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxdv02.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxdv03.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxdv04.jpg' className="img-fluid" ></img>

            </Row>
        </div>
    )
} 
function Saison_23_24() {
    return(
        <div>
            <h1>Saison 2023/24</h1>
            <hr />
            <Row xs={1} md={1} lg={2} className='g-4'>
            <img src='img/galerie/mkxv01.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxv02.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxv03.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxv04.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxv05.jpg' className="img-fluid" ></img>
            <img src='img/galerie/mkxv06.jpg' className="img-fluid" ></img>

            </Row>
        </div>
    )
}
function Saison_24_25() {
    return(
        <div>
            <h1>Saison 2024/25</h1>
            <hr />
            <Row xs={1} md={1} lg={2} className='g-4'>
            <img src='img/galerie/platzhalter01.png' className="img-fluid" ></img>
            <img src='img/galerie/platzhalter02.png' className="img-fluid" ></img>
            </Row>
        </div>
    )
} 
export {Saison_17_18, Saison_18_19, Saison_20_21, Saison_21_22, Saison_22_23, Saison_23_24, Saison_24_25};