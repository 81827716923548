 import Member from '../comps/Member.jsx';
 import Row from 'react-bootstrap/Row';

 function Team() {
    /*return(
        <div>
        <h2>Unser Team 2022/2023</h2>
        <p>Jedes Projekt ist nur so gut, wie die Personen, die das Projekt unterstützen. Hier seht ihr diejenigen, die für Mkx. DV verantwortlich sind.</p>
        <h3>Vorstand</h3>        
        <Row xs={1} md={2} lg={3}>
        <Member name="Tim Güldenpfennig" function="Vorsitzender" src="" />
        <Member name="Marvin Bellmann" function="stellv. Vorsitzender" src=""/>
        </Row><br/>
        <h3>Modulleiter</h3>
        <Row xs={1} md={2} lg={3}>
        <Member name="Tim Hübner" function="Head of MB" src="img/members/huebner.jpg" />
        <Member name="Sami Fatal Al-Yabroudi" function="Head of ET" src="img/members/sami.jpeg"/>
        </Row><br/>
        <h3>Sonstige Mitglieder</h3>
        <Row xs={1} md={2} lg={3}>
        <Member name="Remo-Peter Stiegler" function="ET-try-hard" src="img/members/remo.jpg" />
        <Member name="Dr. habil. Martin Dymek" function="Softwareexperte" src="img/members/martin.jpg"/>
        </Row>
        </div>
    )*/
return(
    <div>
        <h2>Team</h2>
        <p>Wir sind das T.U.C. Racing Team  und vertreten die Technische Universität Chemnitz bei dem internationalen Konstruktionswettbewerb „Formula Student“. Wir sind ein bunt gemischtes Team bestehend aus Technikbegeisterten, Organisationstalenten und leidenschaftlichen Rennfahrern. Gemeinsam arbeiten wir an einem klaren Ziel: die Entwicklung unseres eigenen konkurrenzfähigen Rennwagens mit E-Motor. Dabei stehen für uns Innovationskraft, Nachhaltigkeit und ein starker Teamgeist im Fokus.
        </p><p>
        Wir laden dich herzlich ein, das T.U.C. Racing Team näher kennenzulernen und gemeinsam mit uns die Formula Student  zu erleben.
        </p> 
        <hr />
        <h3>Vorstand</h3>
        <br />
        <Row xs={1} md={2} lg={3}>
            <Member name="Ivy Fox" function="Teamcaptain" src="img/members/Ivy_Fox.jpg"></Member>
            <Member name="Jakob Hammoud" function="Technical Projectlead" src="img/members/Jakob_Hammoud.jpg"></Member>
            <Member name="Daniel Langheinrich" function="Organisational Projectlead / Head of Finance" src="img/members/Daniel_Langheinrich.jpg"></Member>
        </Row>
        <hr />
        <h3>Modulleiter</h3>
        <br />
        <Row xs={1} md={2} lg={3}>
		    <Member name="Nicolai Winkler" function="Head of Aerodynamics" src="img/members/Nicolai_Winkler.jpg"></Member>
			<Member name="Florian Karl" function="Head of Accumulator" src="img/members/Florian_Karl.jpg"></Member>
			<Member name="Sebastian Schulz" function="Head of Driverless" src="img/members/Sebastian_Schulz.jpg"></Member>
			<Member name="Carl Hanke" function="Head of Electronics" src="img/members/Carl_Hanke.jpg"></Member>
			<Member name="Paul Friedrich" function="Head of Ergonomics" src="img/members/Paul_Friedrich.jpg"></Member>
            <Member name="Oliver Hößler" function="Head of Drivetrain" src="img/members/Oliver_Hößler.jpg"></Member>
            <Member name="Ahmed Alzuabidi" function="Head of Frame & Body" src="img/members/Ahmed_Alzuabidi.jpg"></Member>
            <Member name="Alexander Göthel" function="Head of Suspension" src="img/members/Alexander_Göthel.jpg"></Member>
			<Member name="Laura Menzel" function="Head of Marketing & Management" src="img/members/Laura_Menzel.jpg"></Member>
			<Member name="Vincent König" function="Head of Powertrain" src="img/members/Vincent_König.jpg"></Member>

        </Row>
    </div>
);
 }

 export default Team;
