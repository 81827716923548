import {Container, Row, Col} from 'react-bootstrap';

function Footer(){
    return(
        <div id="footer">
            <div id="footer-in">
            <Container>
                <Row xs={1} md={2} lg={4} className="g-4">
                    <Col>
                        <h2>Rechtliches</h2>
                        <br />
                        <a href="/imprint">Impressum</a>
                        <br />
                        <a href="/kontakt">Kontakt</a>
                        <br />
                        <a href="/dataprotection">Datenschutz</a>
                        <br />
                        <br />
                    </Col>
                    <Col>
                    <h2>Social Media</h2>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center'}} >
                            <a href="https://www.instagram.com/tucracing/">
                                <img src="img/icons/instaw.png" alt="Instagram" title="Instagram"  style={{margin: '0 10px'}}/>
                            </a>
                            <a href="https://www.linkedin.com/company/tucracing/">
                                <img src="img/icons/linkedinw.png" alt="LinkedIn" title="LinkedIn"  style={{margin: '0 10px'}}/>
                            </a>
                            <a href="https://www.facebook.com/tuchemnitzracing/">
                                <img src="img/icons/facebookw.png" alt="Facebook" title="Facebook"  style={{margin: '0 10px'}}/>
                            </a>
                        </div>
                        <br />
                        <br />
                    </Col>
                    <Col>
                    <h2>Unterstützung</h2><br />
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                    <input type="hidden" name="cmd" value="_s-xclick"/>
                    <input type="hidden" name="hosted_button_id" value="KFF7XUPGZ7CJ4"/>
                    <input type="image" src="img/paypal_logo.png" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Spenden mit dem PayPal-Button" width="250" border="0" />
                    <img alt="" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" wc6l9mai3="" width="1" height="1" border="0" />
                    </form>
                    </Col>
                
                    <Col><h2>Copyright</h2><br></br><p>T.U.C. Racing e.V. © 2015-2025</p><a href="https://sites.google.com/tuc-racing.de/team/startseite">Intranet</a></Col>
            </Row></Container>
            
            </div>
        </div>
    );
}
export default Footer;